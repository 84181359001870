<template>
<div style="width:100%">
  <v-sheet :color="colores.primario" style="padding:1px" rounded>
    <v-card flat>
      <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '20px' }]"><b>CERRAR QR</b></span></v-toolbar>
      <v-card-text class="pt-0">
        <v-form ref="form_cerrar">
            <v-row no-gutters v-if="dataReplicaProrroga(registro) === 1">
              <v-col cols="12" md="6">
                <texto2 :colores="colores" :titulo="'PRÓRROGA'" :descripcion="dataReplica[0].prorroga.descripcion"></texto2>
              </v-col>
            </v-row>
            <v-row v-else no-gutters>
              <v-col cols="12" md="6">
                <v-autocomplete v-model="form_cerrado.prorroga_id" v-on:input="input_prorroga(form_cerrado.prorroga_id)" :rules="[rules.requerido]" :items="items.tiposi" item-text="descripcion" item-value="id" label="PRÓRROGA" :color="colores.primario"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="6" v-if="form_cerrado.prorroga_id === 29">
              {{ dataReplica[0].fechaparacerrarexterno.substr(0, 10)}} LA PRÓRROGA SE EXTENDERA 5 DIAS HÁBILES {{ dataReplica[0].fechaProrrogaAct.substr(0, 10)}}
              </v-col>
            </v-row>
            <div v-if="(dataReplicaProrroga(registro) === 1 ||  form_cerrado.prorroga_id === 30)">
              <v-row v-if="registro.gesduplicado.marcacion_id === null">
                <v-col >
                      <v-switch
                      v-model="switch1"
                      color="success"
                      label="Realizar Marcación"
                      @change="seleccionMarcacion('switch')"
                      ></v-switch>
                    </v-col>
              </v-row>
              <v-row no-gutters v-if="showMarcacion">
                <v-col cols="12" align-self="center">
                  <v-toolbar dense flat><span :style="[{'color': colores.primario}, {'font-size': '16px' }]"><b>MARCACIÓN</b></span></v-toolbar>
                  <v-radio-group v-model="form_duplicado.marcacion_id" :rules="[rules.requerido]" hide-details dense>
                    <div v-for="(item, index) in items_marcacion" :key="index">
                    <v-radio :color="colores.primario" :label="item.descripcion" :value="item.id"></v-radio>
                    <v-divider></v-divider>
                    </div>
                  </v-radio-group>
                </v-col>
              </v-row>
              <desistimientoFormReplica :session="session" :registro="registro" :form_cerrado="form_cerrado" :items="items" :colores="colores" :rules="rules" :dataReplica="dataReplica" @respuestaFinalFile="actualizarRepuestaFinal"></desistimientoFormReplica>
            </div>
            <br>
            <v-row no-gutters v-if="session.area !== 17">
                <v-col cols="12" class="text-center">
                  <!-- BOTON GUARDAR  CERRAR QR -->
                  <v-btn @click="validar" block large :color="colores.black" dark>CERRAR Y DAR RESPUESTA CON LA RÉPLICA</v-btn>
                </v-col>
              </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-sheet>
  <br><br>
  <mensajesSave :colores="colores" :dialogo="dialogo" :items_errores="items_errores" @closed_mensaje_dialogo="cerrar_mensaje_dialogo"></mensajesSave>
</div>
</template>

<script>
import recGestiones from '@/js/rec_gestiones.js'
import recFunciones from '@/js/rec_funciones.js'
import recListado from '@/js/rec_listado.js'
// import mensajesSave from '@/components/widgets/mensajes_saveComponent.vue'
// import desistimientoForm from '@/components/crm/form_qr_cerrar_desistimientosComponent.vue'
// import consumidorForm from '@/components/crm/form_qr_cerrar_consumidorComponent.vue'
// import tutelaForm from '@/components/crm/form_qr_cerrar_tutelaComponent.vue'
// import dialogoAnalisis from '@/components/crm/form_qr_cerrar_dialogoComponent.vue'

export default {
  name: 'form_qr_cierreComponent',
  components: {
    texto2: () => import('@/components/widgets/text2Component.vue'),
    desistimientoFormReplica: () => import('@/components/crm/form_qr_cerrar_desistimientosReplicaComponent.vue'),
    mensajesSave: () => import('@/components/widgets/mensajes_saveComponent.vue')
  },
  mixins: [recFunciones, recGestiones, recListado],
  props: ['colores', 'registro', 'rules', 'gestion_id', 'session', 'form_otro', 'items', 'token_area'],
  data: () => ({
    tokenAnalisis: 0,
    form_consumidor: { gestion_id: 0, aceptacion_id: 0, rectificacion_id: 0 },
    form_tutela: { gestion_id: 0, resultado_id: 0 },
    form_cerrado: { gestion_id: 0, desistimiento_id: null, prorroga_id: null, procorreoenvio_id: 0, fincorreoenvio_id: 0, resumen: '', favorabilidad_id: null, fechaparacerrarprorroga: null, replica_id: 0 },
    dialogo: { respuestaFinalFile: false, incompleto: false, pregunta: false, pregunta_anular: false, esperar: false, finalizo: false, barfinalizo: false, barfinalizoanulo: false, detalle: false, error: false, objPayload: null },
    items_errores: [],
    switch1: false,
    opcion: 0,
    items_marcacion: [],
    showMarcacion: false,
    form_duplicado: { marcacion_id: null },
    dataReplica: [],
    estadoRespuestaFinalFile: 0
  }),
  watch: {
    token_area: {
      immediate: true,
      handler (newVal, oldVal) {
        this.inicializar_cierre()
      }
    }
  },
  methods: {
    validar () {
      if (this.$refs.form_cerrar.validate()) {
        if (this.estadoRespuestaFinalFile === 0 && this.form_cerrado.prorroga_id === 30) {
          this.dialogo.respuestaFinalFile = true
        } else {
          if (this.form_cerrado.prorroga_id === 30) {
            this.dialogo.pregunta = true
          } else {
            this.form_cerrado.fechaparacerrarprorroga = this.dataReplica[0].fechaProrrogaAct
            this.dialogo.pregunta = true
          }
        }
      } else {
        this.dialogo.incompleto = true
        if (this.estadoRespuestaFinalFile === 0 && this.form_cerrado.prorroga_id === 30) {
          this.dialogo.respuestaFinalFile = true
        }
      }
    },
    cerrar_mensaje_dialogo (newvalue) {
      if (newvalue === 'PREGUNTA') this.rec_gestion_cierre_tipo_replica() // this.rec_gestion_cierre_odt()
      else if (newvalue === 'CANCELARPREGUNTAR') this.dialogo.pregunta = false
      else if (newvalue === 'FINALIZO') {
      } else if (newvalue === 'DETALLES') {
        this.dialogo.detalle = false
      }
    },
    inicializar_cierre () {
      this.form_consumidor.gestion_id = 0
      this.form_consumidor.aceptacion_id = 0
      this.form_consumidor.rectificacion_id = 0

      this.form_tutela.gestion_id = 0
      this.form_tutela.resultado_id = 0

      this.form_cerrado.desistimiento_id = null
      this.form_cerrado.prorroga_id = null
      this.form_cerrado.respuesta = ''
      this.form_cerrado.resumen = ''
      this.form_cerrado.favorabilidad_id = null
      this.form_cerrado.fechaparacerrarprorroga = null
    },
    input_prorroga (pProrrogaId) {
      this.form_cerrado.desistimiento_id = null
      this.form_cerrado.respuesta = ''
      this.form_cerrado.resumen = ''
      this.form_cerrado.favorabilidad_id = null
      this.form_cerrado.procorreoenvio_id = 0
      this.form_cerrado.fincorreoenvio_id = 0
      this.form_cerrado.fechaparacerrarprorroga = null
    },
    seleccionMarcacion () {
      this.switch1 ? this.opcion = 1 : this.opcion = 2
      this.items_marcacion = []
      this.form_duplicado.marcacion_id = null
      if (this.opcion === 1) {
        this.items.marcaciones.forEach(element => {
          if ((element.descripcion !== 'Si La Queja O Reclamo Fue Cerrada Por Falta De Competencia' &&
              element.descripcion !== 'Si La Queja Fue Cerrada Por Estar Repetida En Smartsupervision') || (element.id !== 485 && element.id !== 488)) {
            this.items_marcacion.push(element)
          }
        })
        this.showMarcacion = true
      } else if (this.opcion === 2) {
        this.showMarcacion = false
      }
      this.items_marcacion.forEach(element => {
        if (element.id === 482) {
          element.descripcion = 'Si La Queja O Reclamo Corresponde A Una Réplica, Es Decir, Si El Consumidor Financiero Solicita Reabrir La Queja O Reclamo Por Inconformidad Con La Respuesta Dada Por La Entidad Vigilada Y/o El Defensor Del Consumidor Financiero, Dentro De Los Dos (2) Meses Siguientes A La Fecha Final De Cierre De La Última Respuesta'
        }
      })
    },
    dataReplicaProrroga (registro) {
      let existe = 0
      let cont = 0
      for (let i = 0; i < registro.replicas.length; i++) {
        if (registro.replicas[i].fechacerrado === null) {
          this.dataReplica[cont] = registro.replicas[i]
          cont++
        }
      }
      if (this.dataReplica[0].prorroga_id != null) {
        existe = 1
      }
      return existe
    },
    actualizarRepuestaFinal (estado) {
      this.estadoRespuestaFinalFile = estado
    }
  }
}
</script>
